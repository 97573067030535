import React from 'react';
import dynamic from 'next/dynamic';

import PageLoader from 'Components/common/pageLoader';

import { isServer } from 'Utils/envUtils';
import { getMetaDescription, getMetaTags, getTitle } from 'Utils/homePage';
import { getLocalizedSelfCanonicalUrl } from 'Utils/localizationUtils';
import PlatformUtils from 'Utils/platformUtils';
import { redirectTo } from 'Utils/redirectUtils';
import {
	getCurrentCityCode,
	getUserLandingUrl,
	isBot as isBotfromStateUtils,
} from 'Utils/stateUtils';
import {
	getCurrentUrl,
	getLocationObjectFromRouter,
	getWebPathString,
} from 'Utils/urlUtils';

import { fetchCategoriesByCityCode } from 'Thunks/categories';
import { fetchCities } from 'Thunks/city';
import { fetchCollections } from 'Thunks/collections';
import { fetchProductListByTag } from 'Thunks/productList';
import { changeCity } from 'Actions/city';
import { changePage } from 'Actions/page';

import {
	COLLECTIONS_LIMIT,
	GLOBAL_CITY_CODE,
	GLOBAL_HEADOUT_PICKS_TAG,
	PRODUCT_LIST_LIMIT,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

const HomePageDesktop = dynamic(() => import('Containers/desktop/homePage'), {
	loading: () => <PageLoader />,
});
const HomePageMobile = dynamic(() => import('Containers/mobile/homePage'), {
	loading: () => <PageLoader />,
});

const HomePage = (props: any) => {
	return PlatformUtils.isDesktop() ? (
		<HomePageDesktop {...props} />
	) : (
		<HomePageMobile {...props} />
	);
};

HomePage.getInitialProps = async ({
	store,
	query,
	req,
	res,
	pathname,
	asPath,
	localizedStrings,
}: any) => {
	const { lang } = query;
	const [path] = asPath?.split('?') || [];
	const state = store.getState();
	const currentUrl = req ? getCurrentUrl(req) : window.location.href;
	const isBot = isBotfromStateUtils(state);

	/* PrePush Server Functions */
	const userLandingUrl = getUserLandingUrl(state);
	if (userLandingUrl) {
		return redirectTo(res, userLandingUrl);
	}
	const cityCode = getCurrentCityCode(state);
	const location = getLocationObjectFromRouter(query, pathname, asPath);
	if (cityCode && cityCode !== 'null' && !isBot && isServer()) {
		return redirectTo(
			res,
			`${lang ? `/${lang}` : ''}/-city-${getWebPathString(cityCode)}/${
				location.search
			}`,
		);
	} else {
		await store.dispatch(changeCity({ cityCode: null }));
	}

	/* PreRender Server Functions */
	await Promise.all([
		store.dispatch(changePage()),
		store.dispatch(fetchCities()),

		store.dispatch(
			fetchCollections({
				cityCode: GLOBAL_CITY_CODE,
				lang,
				limit: COLLECTIONS_LIMIT.GLOBAL_HOMEPAGE,
			}),
		),
		store.dispatch(
			fetchCategoriesByCityCode({ cityCode: GLOBAL_CITY_CODE, lang }),
		),
		store.dispatch(
			fetchProductListByTag({
				cityCode: GLOBAL_CITY_CODE,
				params: `sort-type=${GLOBAL_HEADOUT_PICKS_TAG}`,
				lang,
				req,
				tag: GLOBAL_HEADOUT_PICKS_TAG,
				limit: PRODUCT_LIST_LIMIT.DEFAULT,
			}),
		),
	]);

	if (localizedStrings) {
		strings.setContent({
			default: localizedStrings,
		});
	}

	return {
		helmetFunctions: {
			title: getTitle({ lang }),
			canonicalUrl: getLocalizedSelfCanonicalUrl(lang, path),
			metaDescription: getMetaDescription({ lang }),
			metaTags: getMetaTags(),
			currentUrl,
		},
		params: query,
		location,
	};
};

export default HomePage;
