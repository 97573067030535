import queryParser from 'query-string';

import { notify } from 'Components/common/notify';

import { checkLastVisitedInDays } from 'Utils/dateUtils';
import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { generateFilterKey } from 'Utils/gen';
import { deDupeRecentProductIdList, read } from 'Utils/localStorageUtils';
import { error } from 'Utils/logUtils';
import {
	getCurrentCurrency,
	getCurrentLanguageCode,
	shouldFetch,
} from 'Utils/stateUtils';
import {
	addQueryParamsToUrl,
	addQueryParamToString,
	getApiCDNBaseUrlV2,
} from 'Utils/urlUtils';
import { isNumber } from 'Utils/validationUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	receiveProductList,
	receiveRecentProductIdList,
	requestProductList,
	requestRecentProductIdList,
} from 'Actions/product';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';
import { TPersonaProductListThunkParam } from 'ReduxTypes/persona';

import {
	GLOBAL_CITY_CODE,
	NEXT_NEW_PRODUCTS_PAGINATION,
	QUERY_PARAM,
	RECENTLY_VIEWED_ENTITIES,
	SORT_TYPE_RECENTLY_VIEWED,
} from 'Constants/constants';

import { fetchProductCards } from './product';

export const fetchProductList =
	({
		cityCode,
		params,
		nextPageUrl = null,
		limit = 12,
		useSeatmapPrices = false,
		lang = 'en',
		req,
	}: any) =>
	(dispatch: any, getState: any) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const unavailableTours = '&include-unavailable=true';
		const limitParam =
			params.indexOf('limit=') > -1 ? '' : `&limit=${limit}`;
		const url = nextPageUrl
			? `${getApiCDNBaseUrlV2({ state: getState() })}${nextPageUrl}`
			: `${getApiCDNBaseUrlV2({
					state: getState(),
			  })}/api/v6/tour-groups/list-by/city/${cityCode}?${params}${limitParam}${currencyParam}${langParam}&use-seatmap-prices=${useSeatmapPrices}${unavailableTours}`;
		if (!shouldFetch(getState(), url, 120)) return Promise.resolve();

		dispatch(requestProductList({ cityCode, params }));
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: json,
						nextPage: nextPageUrl !== null,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchRecentProductIdList =
	({
		cityCode = GLOBAL_CITY_CODE,
		lang = 'en',
	}: {
		cityCode: string;
		lang: string;
	}) =>
	(dispatch: any) => {
		const params = SORT_TYPE_RECENTLY_VIEWED;
		dispatch(requestRecentProductIdList({ cityCode, params }));
		let products = [];
		const dataFromLocal = read(RECENTLY_VIEWED_ENTITIES);
		products = dataFromLocal
			?.filter(
				({ entityType }: { entityType: string }) =>
					entityType === 'product',
			)
			?.filter(({ lastVisited }: { lastVisited: number }) =>
				lastVisited ? checkLastVisitedInDays(lastVisited, 30) : false,
			)
			?.sort((a: any, b: any) => b.lastVisited - a.lastVisited);

		if (cityCode && cityCode !== GLOBAL_CITY_CODE)
			products = products?.filter(
				({ cityCode: productCityCode }: any) =>
					productCityCode === cityCode,
			);
		else if (cityCode === GLOBAL_CITY_CODE)
			products = deDupeRecentProductIdList(products);

		const productIdList = products?.map((p: any) => p.id) || [];

		dispatch(
			receiveRecentProductIdList({ cityCode, params, productIdList }),
		);
		dispatch(
			fetchProductCards({
				productIds: productIdList,
				lang,
			}),
		);
	};

export const fetchProductListByCategoryId =
	({
		cityCode = GLOBAL_CITY_CODE,
		categoryId,
		params,
		nextPageUrl = null,
		limit = 12,
		useSeatmapPrices = false,
		lang = 'en',
		req,
		subCategoryIds = [],
	}: any) =>
	(dispatch: any, getState: any) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const cityParams =
			cityCode && cityCode !== GLOBAL_CITY_CODE
				? `&city=${cityCode}`
				: '';
		const limitParam =
			params.indexOf('limit=') > -1 ? '' : `&limit=${limit}`;
		const subCategoryFilterParam = subCategoryIds.length
			? `&subCategoryIds=${subCategoryIds.join(', ')}`
			: '';
		let parsedQueryParams = queryParser.parse(params);

		if (
			parsedQueryParams[QUERY_PARAM.LIMIT] &&
			!isNumber(parsedQueryParams[QUERY_PARAM.LIMIT] as string)
		) {
			parsedQueryParams[QUERY_PARAM.LIMIT] =
				NEXT_NEW_PRODUCTS_PAGINATION.toString();
		}

		if (
			parsedQueryParams[QUERY_PARAM.OFFSET] &&
			!isNumber(parsedQueryParams[QUERY_PARAM.OFFSET] as string)
		) {
			parsedQueryParams[QUERY_PARAM.OFFSET] = '0';
		}

		const parsedQueryParamsString =
			queryParser.stringify(parsedQueryParams);

		const url = nextPageUrl
			? `${getApiCDNBaseUrlV2({ state: getState() })}${nextPageUrl}`
			: `${getApiCDNBaseUrlV2({
					state: getState(),
			  })}/api/v6/tour-groups/list-by/category/${categoryId}?${parsedQueryParamsString}${cityParams}${limitParam}${currencyParam}${langParam}&use-seatmap-prices=${useSeatmapPrices}${subCategoryFilterParam}`;
		if (!shouldFetch(getState(), url, 120)) return Promise.resolve();
		if (!params.includes('categoryId')) {
			//adding categoryId here to be used as redux key
			params = addQueryParamToString(params, { categoryId });
		}
		if (!params.includes('subCategoryIds') && subCategoryIds.length) {
			params = addQueryParamToString(params, {
				subCategoryIds: generateFilterKey(
					...[...subCategoryIds].sort(),
				),
			});
		}
		dispatch(requestProductList({ cityCode, params }));
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: json,
						nextPage: nextPageUrl !== null,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchProductListBySubCategoryId =
	({
		cityCode = GLOBAL_CITY_CODE,
		subCategoryId,
		params,
		nextPageUrl = null,
		limit = 12,
		lang = 'en',
		req,
	}: any) =>
	(dispatch: any, getState: any) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const cityParams =
			cityCode && cityCode !== GLOBAL_CITY_CODE
				? `&city=${cityCode}`
				: '';
		const useSeatMap = `&use-seatmap-prices=1`;
		const limitParam =
			params.indexOf('limit=') > -1 ? '' : `&limit=${limit}`;

		let parsedQueryParams = queryParser.parse(params);

		if (
			parsedQueryParams[QUERY_PARAM.LIMIT] &&
			!isNumber(parsedQueryParams[QUERY_PARAM.LIMIT] as string)
		) {
			parsedQueryParams[QUERY_PARAM.LIMIT] =
				NEXT_NEW_PRODUCTS_PAGINATION.toString();
		}

		if (
			parsedQueryParams[QUERY_PARAM.OFFSET] &&
			!isNumber(parsedQueryParams[QUERY_PARAM.OFFSET] as string)
		) {
			parsedQueryParams[QUERY_PARAM.OFFSET] = '0';
		}

		const parsedQueryParamsString =
			queryParser.stringify(parsedQueryParams);

		const url = nextPageUrl
			? `${getApiCDNBaseUrlV2({ state: getState() })}${nextPageUrl}`
			: `${getApiCDNBaseUrlV2({
					state: getState(),
			  })}/api/v6/tour-groups/list-by/sub-category/${subCategoryId}?${parsedQueryParamsString}${cityParams}${limitParam}${currencyParam}${langParam}${useSeatMap}`;
		if (!shouldFetch(getState(), url, 120)) return Promise.resolve();

		if (!params.includes('subCategoryId')) {
			//adding subCateogryId here to be used as redux key
			params = addQueryParamToString(params, {
				subCategoryId,
			});
		}
		dispatch(requestProductList({ cityCode, params }));
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: json,
						nextPage: nextPageUrl !== null,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchProductListByTag =
	({
		tag,
		cityCode,
		params,
		nextPageUrl = null,
		limit = 24,
		useSeatmapPrices = false,
		lang = 'en',
		req,
	}: any) =>
	(dispatch: any, getState: any) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const limitParam =
			params.indexOf('limit=') > -1 ? '' : `&limit=${limit}`;
		const url = nextPageUrl
			? `${getApiCDNBaseUrlV2({ state: getState() })}${nextPageUrl}`
			: `${getApiCDNBaseUrlV2({
					state: getState(),
			  })}/api/v6/tour-groups/list-by/tag/${tag}?${params}${limitParam}${currencyParam}${langParam}&use-seatmap-prices=${useSeatmapPrices}`;
		dispatch(requestProductList({ cityCode, params }));
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: json,
						nextPage: nextPageUrl !== null,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchProductListByPersonaId =
	({
		city,
		personaAffinityId,
		offset = 0,
		limit = 12,
		useSeatMap = true,
		includeHidden = false,
		includeUnavailable = false,
		req,
		categories = [],
		subCategories = [],
	}: TPersonaProductListThunkParam) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state).toUpperCase();
		const currencyCode = getCurrentCurrency(state);
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const urlBase = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v6/tour-groups/list-by/persona-affinity/${personaAffinityId}`;

		const url = `${addQueryParamsToUrl(urlBase, {
			city,
			'use-seatmap-prices': useSeatMap ? 1 : 0,
			'include-hidden': includeHidden,
			'include-unavailable': includeUnavailable,
			language: lang,
			offset,
			limit,
			categoryIds: categories.join(','),
			subCategoryIds: subCategories.join(','),
		})!}${currencyParam}`;
		if (!shouldFetch(getState(), url, 120)) return Promise.resolve();

		const personaParam = `pa-${personaAffinityId}-${
			generateFilterKey(...[...categories, ...subCategories].sort()) ||
			'DEFAULT'
		}`;

		dispatch(
			requestProductList({
				cityCode: city.toUpperCase(),
				params: personaParam,
			}),
		);
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode: city.toUpperCase(),
						params: personaParam,
						response: json,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};
